import React from 'react';
import buero from '../media/Buero_Ansicht.JPG';

const Kontakt = () => {
    return (
        <div className='bg-bv_akzent_light w-full text-bv_font_dark shadow-lg my-4'>
            <div className='max-w-[1240px] mx-auto md:flex justify-between flex-shrink'>
                <div className='h-[460px] px-4 md:pl-10'>
                    <img src={buero} alt='Außenansicht Bützow' className='rounded-xl mx-auto object-contain h-[460px] saturate-150 contrast-125' />
                </div>
                <div className='w-full  py-4 px-[10%] flex flex-wrap gap-3 justify-between mx-auto md:w-[60%]'>
                    <div className='w-[200px] mx-auto md:w-[230px]'>
                        <h2 className='text-2xl font-bold md:text-3xl'>Notariat Bützow</h2>
                        <br />
                        <p>Schloßstraße 12</p>
                        <p>18246 Bützow</p>
                        <br />
                        <p>Tel.:  038461 / 3260</p>
                        <p>Fax.:  038461 / 65257</p>
                        <br />
                    </div>
                    <div className='w-[200px] mx-auto md:w-[230px]'>
                        <h3 className='text-2xl font-bold md:text-3xl xl:mb-10'>Öffnungszeiten</h3>
                        <br />
                        <ul>
                            <li>Mo-Do: <br />08.00 bis 12.00 Uhr <br />13.00 bis 17.00 Uhr</li>
                            <li>Fr: <br />08.00 bis 12.00 Uhr</li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Kontakt;