import React, { useState , useEffect } from 'react';
import {AiOutlineClose, AiOutlineMenu, AiFillHome, AiFillContacts} from 'react-icons/ai';
import {BsFillFileEarmarkTextFill} from 'react-icons/bs';
import {RiGitRepositoryPrivateFill} from 'react-icons/ri';


const Navbar = ({handler}) => { 
    const [nav, setNav] = useState(true);
    const handleNav = () =>{
        setNav(!nav);
    };
    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
      ]);
    
      useEffect(() => {
        const handleWindowResize = () => {
          setWindowSize([window.innerWidth, window.innerHeight]);
        };
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      });

    return (
        <div className="max-w-[1240px] mx-auto z-[99] bg-bv_bg_light">
            <div className='w-full bg-[#B9B8B3] h-[150px] flex flex-nowrap'>
                <div className='h-full w-[40%] bg-[#919587]'></div>
                <div className='h-full w-[25%] bg-[#7A8072]'></div>
                <div className='h-full w-[15%] bg-[#A2A499]'></div>
                <div className='h-full w-[20%] bg-[#B9B8B3]'></div>
            </div>
            <div className='absolute md:max-w-[1000px] top-10 px-6 mx-auto container flex justify-between items-center'>
            <a href='/'><h1 className={windowSize[0] > 300 ? 'text-3xl md:text-4xl text-bv_font_light font-bold' :'text-2xl text-bv_font_light font-bold'}>Notarin Sabine Klink</h1></a>
            {/* Sidepanel Navbar Mobile */}
            <div onClick={handleNav} className="block text-bv_font_dark md:hidden">
                {!nav ? <AiOutlineClose size={30} /> : <AiOutlineMenu size={30} />}
            </div>
            <div className={!nav ? 'fixed top-[150px] bg-[#919587] left-0 w-[40%] min-w-[180px] h-full ease-in-out duration-500' : 'fixed left-[-100%] top-[150px] w-[35%] h-full ease-in-out duration-500'}>
                <ul className='pt-2 uppercase'>
                    <li className='cursor-pointer text-bv_font_light hover:text-bv_font_dark hover:bg-[#B9B8B3] flex items-center p-4 font-bold border-b-2'> 
                        <span className='pr-3'><AiFillHome></AiFillHome></span>
                        <a href='/'>Home</a>
                    </li>
                    <li className='cursor-pointer text-bv_font_light hover:text-bv_font_dark hover:bg-[#B9B8B3] flex items-center p-4 font-bold border-b-2 '>
                        <span className='pr-3'><RiGitRepositoryPrivateFill></RiGitRepositoryPrivateFill></span>
                        <a href='/Datenschutz'>Datenschutz</a>
                    </li>
                    <li className='cursor-pointer text-bv_font_light hover:text-bv_font_dark hover:bg-[#B9B8B3] flex items-center p-4 font-bold border-b-2 '>
                        <span className='pr-3'><BsFillFileEarmarkTextFill></BsFillFileEarmarkTextFill></span>
                        <a href='/Impressum'>Impressum</a>
                    </li>
                    <li className='text-bv_font_light hover:text-bv_font_dark hover:bg-[#B9B8B3] flex items-center p-4 font-bold'>
                        <span className='pr-3'><AiFillContacts></AiFillContacts></span>
                        <p className='cursor-pointer' onClick={()=>{
                            handler();
                            handleNav();
                            }}>Kontakt</p>
                    </li>
                </ul>
            </div>
            </div>
            {/* Sidepanel Navbar Desktop */}
            <ul className='hidden text-bv_font_dark my-2 md:flex'>
                <li className='cursor-pointer px-4 font-bold hover:scale-105'><a href='/'>Home</a></li>
                <li className='cursor-pointer px-4 font-bold hover:scale-105'><a href='/Datenschutz'>Datenschutz</a></li>
                <li className='cursor-pointer px-4 font-bold hover:scale-105'><a href='/Impressum'>Impressum</a></li>
                <li className='cursor-pointer px-4 font-bold hover:scale-105'><p onClick={handler}>Kontakt</p></li>
            </ul>
        </div>
    );
}

export default Navbar;