import Home from './components/Home';
import Datenschutz from './components/Datenschutz';
import Impressum from './components/Impressum';
import Navbar from './components/Navbar';
import {Routes, Route} from 'react-router-dom';
import {useRef} from 'react';
import Footer from './components/Footer';

//import logo from './logo.svg';

function App() {
  const contact = useRef();
  const handleNavClick = () => {
    contact.current.scrollIntoView({ behavior: 'smooth' })    
};

  return (
    <div className="App bg-bv_bg_light">
      <header className='w-full sticky md:relative top-0 z-[99]'>
        <Navbar handler={handleNavClick}/>
      </header>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/Datenschutz' element={<Datenschutz />} />
        <Route path='/Impressum' element={<Impressum />} />
      </Routes>
      <Footer ref={contact} />
    </div>
  );
}

export default App;
