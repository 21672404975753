import React, {useState} from 'react';
import { BsFillArrowDownCircleFill, BsFillArrowUpCircleFill } from "react-icons/bs";
import TaetigFelder from './TaetigFelder';
import { tList } from './tFelder';

const Taetigkeiten = () => {
    const [textHide, setText] = useState(true);
    
    const handleElemHide = () => {
        setText(!textHide)
    }
    
    return (
        <div className='py-4'>
            <div className='bg-bv_akzent_light text-bv_font_dark w-full  shadow-lg my-4 pb-4'>
                <div className='max-w-[1240px] mx-auto px-10 py-4 '>
                    <h2 className='font-bold mb-4 text-center text-3xl md:text-4xl'>Notarielle Tätigkeitsfelder</h2>
                    <p>
                    Im Mittelpunkt der Aufmerksamkeit des Notars steht immer der Mensch. Denn ebenso wichtig wie äußerste Genauigkeit und fundiertes Wissen ist Verständnis für Ihre Anliegen, Sorgen und Nöte.
                    <br /><br />
                    Notarinnen und Notare sind zuständig für Beurkundungen jeder Art sowie für die Beglaubigung von Unterschriften, Handzeichen und Abschriften. Für eine Vielzahl von Rechtsgeschäften ist die Beurkundung durch einen Notar sogar gesetzlich vorgeschrieben. Dies ist immer dort der Fall, wo der Gesetzgeber die Mithilfe des Notars wegen der weitreichenden persönlichen und wirtschaftlichen Folgen für die Beteiligten für geboten hält.
                    </p>
                    <div className= {
                        textHide ? "hidden" : " my-2" 
                    }>
                        Notare errichten vollstreckbare Urkunden. Aus ihnen findet wie aus für vollstreckbar erklärten gerichtlichen Endurteilen die Zwangsvollstreckung statt. Auch sind Schiedssprüche nur vollstreckbar, wenn sie von einem staatlichen Gericht oder einem Notar für vollstreckbar erklärt wurden.
                        <br /><br />
                        Darüber hinaus nehmen sie Verlosungen und Auslosungen vor und erstellen Vermögensverzeichnisse. Sie können aber auch freiwillige Versteigerungen durchführen und Vermittlung von Nachlass- und Gesamtgutauseinandersetzungen vornehmen. Daneben können Notare in Angelegenheiten der freiwilligen Gerichtsbarkeit beraten und vertreten, als Schiedsrichter tätig sein, Eide abnehmen, Bescheinigungen ausstellen und Wertpapiere sowie Kostbarkeiten verwahren.
                        <br /><br />
                        
                        <ul className='list-disc list-outside pl-4'>
                            <li>
                                Vorsorgende Rechtspflege. Notarinnen und Notare sind auf dem Gebiet der vorsorgenden Rechtspflege tätig. Sie sind wie Richter staatlich bestellt, unabhängig, unparteilich und Träger eines öffentlichen Amtes. Im Gegensatz zur streitigen Justiz kümmern sich Notarinnen und Notare jedoch um Streitvermeidung und sichere Vertragsgestaltung im Vorfeld. Notarinnen und Notare stehen Ihnen und Ihren Vertragspartnern als verschwiegener Berater in komplizierten und folgenreichen Rechtsangelegenheiten zur Verfügung.</li>
                            <li>
                                Höchste Qualität. Notarinnen und Notare verbinden höchste juristische Ansprüche mit einem Berufsethos, der von Neutralität und der Würde eines öffentlichen Amtes geprägt ist. Zum Notar werden nur besonders qualifizierte und erfahrene Volljuristen bestellt, deren Urkunden für Rechtssicherheit, Rechtsfrieden und Schutz des Unerfahrenen sorgen.</li>
                            <li>
                                Gleichmäßiger Zugang. Der hoheitliche Charakter der Notartätigkeiten verbürgt nicht nur erstklassige vorsorgende Rechtspflege, sondern auch einen gleichmäßigen Zugang zur juristischen Beratung. Das Gerichts- und Notarkostengesetz (GNotKG) sieht ein besonders soziales Gebührensystem vor: Jeder kann sich seinen Notar leisten, weil dessen Kosten sich nach dem Wert der Transaktion richten. Die einer Beurkundung vorausgehende Beratung einschließlich Entwurfsfertigungen sind mit der Beurkundungs­gebühr abgegolten. Dabei kommt es nicht darauf an, wie viel Zeit sich die Notarin oder der Notar genommen hat.</li>
                        </ul>
                    </div>
                    <div className='container mx-auto flex justify-center'>
                        {textHide ? <BsFillArrowDownCircleFill onClick={handleElemHide} className='cursor-pointer' size={30} /> : <BsFillArrowUpCircleFill onClick={handleElemHide} className='cursor-pointer' size={30} /> }
                    </div>
                    
                </div>
            </div>
            <div className='max-w-5xl mx-auto flex justify-center gap-4 flex-wrap'> 
                {
                    tList.map((element, index) => {
                        return (
                                <TaetigFelder key={element.id} title={element.title} icon={element.icon} main={element.main} />
                        );
                    })            
                }
            </div>
        </div>
    );
}

export default Taetigkeiten;