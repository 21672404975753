import React from 'react';
import quoteIcon from '../media/Quotes.svg';
import quoteLightIcon from '../media/Quotes_light.svg';
import warteImg from '../media/Wartebereich_klein.jpg';
import besprTischImg from '../media/Besprechungstisch_klein.jpg';
import helfendeImg from '../media/DerHelfende_klein.jpg';
import portraitTischImg from '../media/Portrait_Tisch.jpg';
import portraitSchrankImg from '../media/Portrait_Schrank.jpg';

function getRandomIntInclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min); // The maximum is inclusive and the minimum is inclusive
  }

const randBild = getRandomIntInclusive(1,2);

const About = () => {
    return (
        <div className='py-4'>
            <div className='bg-bv_akzent_light text-bv_font_dark w-full  shadow-lg my-4'>
                <div className='max-w-[1240px] mx-auto px-10 py-4 relative'>
                    <h2 className='font-bold mb-4 text-center text-3xl md:text-4xl'>Über uns</h2>
                    {
                        randBild > 1 ?<img src={portraitTischImg} alt="Sabine Klink Portrait Tisch" className='min-w-600px object-cover' /> : <img src={portraitSchrankImg} alt="Sabine Klink Portrait am Schrank" className='min-w-600px object-cover' />
                    }
                    <div className='pt-2 md:absolute text md:w-[40%] md:top-20 lg:top-44 md:right-14 md:text-bv_font_light'>
                        <img src={quoteIcon} alt="Zitation" className='w-12 md:hidden' />
                        <img src={quoteLightIcon} alt="Zitation" className='w-12 hidden md:block' />
                        <blockquote>
                        Ich bin nach erfolgreichem Abschluss des Studiums der Rechtswissenschaften an der Humboldt-Universität Berlin seit 1988 als Notarin in Bützow tätig - zunächst im staatlichen Notariat, seit dem 01.09.1990 freiberuflich als Notarin in eigener Kanzlei.
                        <br />
                        <br />
                        Schwerpunkte meiner Tätigkeit sind neben dem Erbrecht das Grundstücksrecht und das Gesellschaftsrecht sowie Familienrecht.
                        <br />
                        <br />
                        <span className='italic'>- Sabine Klink -</span>
                        </blockquote>
                    </div>
                </div>
                <div className='max-w-[1240px] mx-auto px-10 py-4'>
                    <h3 className='font-bold mb-4 text-center text-2xl md:text-3xl'>
                        Der Standort
                    </h3>
                    <div className="flex justify-center gap-3 flex-wrap md:flex-nowrap">
                        <img src={warteImg} className="ease-in duration-300 object-cover w-[600px] h-[400px] md:w-[30%] hover:w-[600px] hover:ease-out hover:duration-300" alt="Wartebereich" />
                        <img src={besprTischImg} className="ease-in duration-300 object-cover w-[600px] h-[400px] md:w-[30%] hover:w-[600px] hover:ease-out hover:duration-300"  alt="Besprechungstisch" />
                        <img src={helfendeImg} className="ease-in duration-300 object-cover w-[600px] h-[400px] md:w-[30%] hover:w-[600px] hover:ease-out hover:duration-300"  alt="Helfende Hand" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;