import React from 'react';
import About from './About';
import CookieBanner from './CookieBanner';
import Kontakt from './Kontakt';
import Notar from './Notar';
import Taetigkeiten from './Taetigkeiten';

const Home = () => {
    return (
        <div className='overflow-hidden'>
            <CookieBanner />
            <Kontakt /> 
            <About />
            <Notar />
            <Taetigkeiten />
        </div>
     );
}

export default Home;