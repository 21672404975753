import React, {useState} from 'react';
import {AiFillPrinter} from "react-icons/ai";

function Datenschutz() {
    const [printState, setPrint] = useState(false);
    const handlePrintState = () => {
        setPrint(!printState);
    }

    const handleNavClick = () => {
        window.scrollTo({top:0, behavior: 'smooth' }) 
        setTimeout(()=>{
            handlePrintState()   
        },1000);
    };
    
    const printItems = () => {
        window.print();
    };

    return ( 
    <div>
        <div className='bg-bv_akzent_light text-bv_font_dark'>
            <div className='max-w-[1240px] mx-auto w-full p-4'>
                <h1 className='text-3xl font-bold text-center'>Datenschutz</h1>
                <div className={printState ? 'absolute top-[160px] md:top-[200px] right-5 lg:right-32 ease-in-out duration-500' : 'absolute top-[160px] md:top-[200px] right-[100%] ease-in-out duration-500'}>
                    <form className='bg-bv_font_dark w-20 h-20 rounded-full z-[99] text-bv_font_light relative'>
                        <label className='mx-auto'>
                        <input type="button"
                        onClick={()=>{
                            // window.print();
                            printItems();
                            setPrint(!printState);
                            }} />
                        <AiFillPrinter size={35} className=" absolute top-6 left-6 mx-auto cursor-pointer"></AiFillPrinter>
                        </label>
                    </form>
                </div>
                <div className='my-4'>
                    <h2 className='text-xl font-bold my-4'>I. Einleitung</h2>
                    <p>Vertraulichkeit und der Schutz der Privatsphäre der Mandanten haben im Notariat einen besonders hohen Stellenwert. Wir (die <b>verantwortliche Stelle</b>) möchten Sie im Folgenden darüber in Kenntnis setzen, welche Informationen wir beim Besuch unserer Webseite erfassen und wie personenbezogene Daten dabei genutzt werden. Personenbezogene Daten sind alle Daten, die auf Sie persönlich beziehbar sind, z. B. Name, IP-Adresse, E-Mail-Adresse, Kontodaten, verwandtschaftliche Beziehungen, Eigentumsverhältnisse und Nutzerverhalten.</p>
                </div>
                <div className='my-4'>
                    <h2 className='text-xl font-bold my-4'>II. Kontaktdaten</h2>
                    <p><b>Verantwortliche Stelle</b> im Sinne der Datenschutz-Grundverordnung (DSGVO) für die Verarbeitung der personenbezogenen Daten ist:<br /><br />
                        Sabine Klink <br></br>
                        Schloßstraße 12 <br></br>
                        18246 Bützow
                    </p>
                    <p>Telefon: 038461 / 3260</p>
                    <p>Telefax: 038461 / 65257</p>
                    <p>E-Mail: <a href={'mailto:notarin.klink@sabine-klink.de'} className='hover:font-bold'>notarin.klink@sabine-klink.de</a><br /><br /></p>
                    <p>Der bestellte Datenschutzbeauftragte ist die Gesellschaft für notariellen Datenschutz mit beschränkter Haftung (GNotDS), Ehrensteinstraße 33, 04105 Leipzig, E-Mail: <a href={'mailto:datenschutz@gnotds.de'} className='hover:font-bold'>datenschutz@gnotds.de</a></p>
                </div>
                
                <div className='my-4'>
                    <h2 className='text-xl font-bold'>III. Automatische Speicherung von Daten durch Besuch unserer Website</h2>
                    <p>Bei der bloß informatorischen Nutzung der Website erheben wir nur die personenbezogenen Daten, die Ihr Browser an unseren Server übermittelt und die für uns technisch erforderlich sind, um Ihnen unsere Website anzuzeigen und die Stabilität und Sicherheit zu gewährleisten. Erfasst werden folgende Informationen:</p>
                    <ul className="my-4 list-outside ml-4 list-disc">
                        <li>IP-Adresse</li>
                        <li>Datum und Uhrzeit der Anfrage</li>
                        <li>Inhalt der Anforderung (konkrete Seite)</li>
                        <li>Browser</li>
                        <li>Zugriffsstatus/HTTP-Statuscode</li>
                        <li>übertragene Datenmenge</li>
                        <li>Website, von der die Anforderung kommt</li>
                        <li>Betriebssystem und dessen Oberfläche</li>
                        <li>Sprache und Version der Browsersoftware.</li>
                    </ul>
                    <p>Die Daten werden ebenfalls in den Logfiles unseres Systems gespeichert. Eine Speicherung dieser Daten zusammen mit anderen personenbezogenen Daten des Nutzers findet nicht statt. Nach spätestens sieben Tagen werden die Daten durch Verkürzung der IP-Adresse auf Domain-Ebene anonymisiert, so dass es nicht mehr möglich ist, einen Bezug zum einzelnen Nutzer herzustellen. <br /><br />
                    Die Rechtsgrundlage für die Verarbeitung der Daten ist Art. 6 Abs. 1 lit. f DSGVO.</p>
                </div>
                <div className='my-4'>
                    <h2 className='text-xl font-bold my-4'>IV. Kontaktmöglichkeit über die Internetseite</h2>
                    <p>Bei Ihrer Kontaktaufnahme mit uns per E-Mail werden die von Ihnen mitgeteilten Daten (Ihre E-Mail-Adresse, ggf. Ihr Name, die Thematik und Ihre Telefonnummer) von uns gespeichert, um Ihr Anliegen bearbeiten zu können und ggf. einen Termin mit Ihnen zu vereinbaren. Die in diesem Zusammenhang anfallenden Daten löschen wir, nachdem die Speicherung nicht mehr erforderlich ist, oder schränken die Verarbeitung ein, falls gesetzliche Aufbewahrungspflichten bestehen. Wir verarbeiten diese Daten zunächst gemäß Art. 6 Abs.1 lit. b) DSGVO, soweit dies zur Erfüllung eines Vertrages oder vorvertraglicher Maßnahmen erforderlich ist. Eine spätere Verarbeitung erfolgt, wenn wir rechtlich dazu verpflichtet sind (lit. c) oder wir eine Aufgabe wahrnehmen, die im berechtigtes Interesse liegt bzw. in Ausübung öffentlicher Gewalt erfolgt (lit. f). <br /><br />
                    Bitte beachten Sie, dass die Vertraulichkeit bei der übermittelten Information per unverschlüsselter E-Mail nicht gewährleistet ist. Wir empfehlen Ihnen daher, uns vertrauliche Informationen ausschließlich über den Postweg zukommen zu lassen. Ihre Daten werden von uns ausschließlich zur Bearbeitung Ihres Anliegens verwendet. Ihre Daten werden streng vertraulich behandelt. Eine Weitergabe an Dritte erfolgt nicht.</p>
                </div>
                <div className='my-4'>
                    <h2 className='text-xl font-bold my-4'>V. Erhebung weiterer Daten</h2>
                    <p>Eine darüberhinausgehende Datenerhebung bei Nutzung unserer Webseite findet nicht statt.</p>
                </div>
                <div className='my-4'>
                    <h2 className='text-xl font-bold my-4'>VI. Ihre Rechte</h2>
                    <p>Bitte kontaktieren Sie uns jederzeit, wenn Sie sich informieren möchten, welche personenbezogenen Daten wir über Sie speichern bzw. wenn Sie diese berichtigen oder löschen lassen wollen. Bitte haben Sie Verständnis, dass Auskunftsanfragen unsererseits nur beantwortet werden dürfen, wenn dem nicht die notarielle Berufsverschwiegenheit gem. § 18 BnotO entgegensteht. Des Weiteren haben Sie das Recht auf Einschränkung der Verarbeitung (Art. 18 DSGVO) sowie ein Widerspruchsrechts gegen die Verarbeitung (Art. 21 DSGVO). <br /><br />
                    In diesen Fällen wenden Sie sich bitte direkt an uns bzw. an <a href={'mailto:datenschutz@gnotds.de'} className='hover:font-bold'>datenschutz@gnotds.de</a>. <br /><br />
                    Das Recht auf Datenübertragbarkeit (Art. 20 DSGVO) besteht gemäß Art. 20 Abs. 3 Satz 2 DSGVO dann nicht, wenn die Datenverarbeitung der Wahrnehmung öffentlicher Aufgaben dient. Sie haben ferner das Recht, sich an eine zuständige Datenschutz-Aufsichtsbehörde zu wenden, wenn Sie der Ansicht sind, dass die Verarbeitung Ihrer personenbezogenen Daten nicht rechtmäßig erfolgt.
                    </p>
                </div>
                <div className='my-4'>
                    <h2 className='text-xl font-bold my-4'>VII. Sicherheit</h2>
                    <p>Wir treffen alle notwendigen technischen und organisatorischen Sicherheitsmaßnahmen, um Ihre personenbezogenen Daten vor Verlust und Missbrauch zu schützen. So werden Ihre Daten in einer sicheren Betriebsumgebung gespeichert, die der Öffentlichkeit nicht zugänglich ist.</p>
                </div>
                <div className='my-4'>
                    <h2 className='text-xl font-bold my-4'>VIII. Aktualität und Änderung dieser Datenschutzerklärung</h2>
                    <p>Diese Datenschutzerklärung ist aktuell gültig und hat den Stand Mai 2018. Durch die Weiterentwicklung unserer Website und Angebote darüber oder aufgrund geänderter gesetzlicher beziehungsweise behördlicher Vorgaben kann es notwendig werden, diese Datenschutzerklärung zu ändern. Die jeweils aktuelle Datenschutzerklärung kann jederzeit auf unserer Webseite unter <a href={'/Datenschutz'} className='font-bold'>Datenschutz</a> von Ihnen abgerufen und <button className='font-bold' onClick={handleNavClick}>ausgedruckt</button> werden.</p>
                </div>
            </div>
        </div>
    </div> );
}

export default Datenschutz;