import React, {useState, useEffect} from 'react';
import parse from 'html-react-parser';
import { BsFillArrowDownCircleFill, BsFillArrowUpCircleFill } from "react-icons/bs";

const TaetigFelder = (data) => {
    const [hideState, setHide] = useState(true);

    const handleHide = () => {
        setHide(!hideState);
    }

    const info = (el ,hideState) => {
        if(!hideState){
            return (
                <div className='my-4'>
                    {parse(el.main)}
                </div>
            )
        }
    }

    // COUNTDOWN TIMER LOGIC
    const [hovered, sethovered] = useState(false);

    const mouseEnterHandler = () => sethovered(true);
    const mouseLeaveHandler = () => sethovered(false);

    const onTimeout = () => {
    // Do action
        setHide(true)
    };

    useEffect(() => {
        const timer = !hovered && setTimeout(onTimeout, 2000);
        return () => {
        clearTimeout(timer);
    };
}, [hovered]);
    ///

    return ( 
        <div onMouseEnter={mouseEnterHandler} onMouseLeave={mouseLeaveHandler} className={hideState ? 'bg-bv_akzent_light w-[300px] text-bv_font_dark p-4 shadow-xl' : 'bg-bv_akzent_light w-full text-bv_font_dark p-4 shadow-xl'}>
            {/* Karte 1 */}
            <div className=''>
                <h2 className='text-center text-xl font-bold mb-4'>{ data.title }</h2>
                <img className='mx-auto h-[300px]' src={data.icon} alt={data.title} />
                <div className='my-6'>
                    {info(data, hideState)}
                </div>
                <div className='mt-2 container mx-auto flex justify-center'>
                    {hideState ? <BsFillArrowDownCircleFill onClick={handleHide} className='cursor-pointer' size={30} /> : <BsFillArrowUpCircleFill onClick={handleHide} className='cursor-pointer' size={30} /> }
                </div>
            </div>
        </div>
     );
}

export default TaetigFelder;