import React, {useState} from 'react';

const Notar = () => {
    const [costHide, setCost] = useState(true);
    const [costMathHide, setMathCost] = useState(true);
    const [costExHide, setExCost] = useState(true);
    
    const handleElemHide = (elem) => {
        if(elem === 3){
            !costHide ? setCost(!costHide) : setCost(costHide);
            !costMathHide ? setMathCost(!costMathHide) : setMathCost(costMathHide);
            setExCost(!costExHide);
        }else if (elem === 2) {
            !costHide ? setCost(!costHide) : setCost(costHide);
            setMathCost(!costMathHide);
            !costExHide ? setExCost(!costExHide) : setExCost(costExHide);
        }
        else{
            setCost(!costHide);
            !costMathHide ? setMathCost(!costMathHide) : setMathCost(costMathHide);
            !costExHide ? setExCost(!costExHide) : setExCost(costExHide);
        }
    }
    
    return (
        <div className='py-4'>
            <div className='bg-bv_akzent_light text-bv_font_dark w-full  shadow-lg my-4 pb-4'>
                <div className='max-w-[1240px] mx-auto px-10 py-4 text-justify'>
                    <h2 className='font-bold mb-4 text-center text-3xl md:text-4xl'>Der Notar</h2>
                    <p>
                    Dem Notar sind als unabhängigem Träger eines öffentlichen Amtes hoheitliche Befugnisse zur vorsorgenden Rechtspflege in Deutschland übertragen. Der Notar übt präventive Rechtskontrolle aus und errichtet Urkunden, die bindende Beweiskraft gegenüber Gerichten haben und unmittelbar vollstreckbar sind.
                    <br />
                    Unparteiisch. Ihnen und Ihren Vertragspartnern stehen die Notarinnen und Notare als unparteiische Berater in komplizierten und folgenreichen Rechtsangelegenheiten zur Verfügung. 
                    <br />
                    Verschwiegen. Über alle Angelegenheiten, die dem Notar im Rahmen seiner Berufsausübung bekannt werden, hat er Verschwiegenheit gegen jedermann zu bewahren und diese Verschwiegenheit auch den bei ihm beschäftigten Personen zur Pflicht zu machen.
                    <br />
                    Modern. Notare nehmen eine Vorreiterrolle im elektronischen Rechtsverkehr ein. Qualifizierte elektronische Signaturen mit Notarattribut genießen inzwischen das gleiche Vertrauen wie Notarsiegel mit der eigenhändigen Unterschrift der Amtsperson.
                    <br />
                    Sicher. Die notarielle Urkunde - mit Brief und Siegel - ist der Inbegriff für Rechtssicherheit. Das Vertrauen, das ihr im Rechtsverkehr entgegengebracht wird, hat einen Ursprung: die Sorgfalt der Notarinnen und Notare.
                    </p>
                </div>
                <div className='max-w-[1240px] mx-auto px-10 border border-bv_akzent_dark py-4 text-justify'>
                    <h3 className='font-bold text-left text-xl cursor-pointer md:text-2xl' onClick={()=>{handleElemHide(1)}}>Notar Kosten</h3>
                    <p className= {
                        costHide ? "hidden ease-in-out duration-500" : " my-2 ease-in-out duration-500" 
                    }>
                        Gebührenerhebungspflicht. Die Notarinnen und Notare sind nach § 17 Abs. 1 Satz 1 der Bundesnotarordnung verpflichtet, für ihre Tätigkeit die im Gerichts- und Notarkostengesetz (GNotKG) gesetzlich vorgeschriebenen Gebühren und Auslagen zu erheben - nicht mehr und nicht weniger. Gebührenvereinbarungen jeder Art sind unzulässig. Regelmäßige Geschäftsprüfungen durch den Präsidenten des Landgerichts bzw. die Notarkasse erstrecken sich auch auf die ordnungsgemäße Gebührenerhebung und Kostenvollstreckung, falls erforderlich.
                        <br /><br />
                        Soziales Gebührensystem. Das Gebührensystem des GNotKG ist sorgfältig austariert. Es führt auch dazu, dass wir Notare viele Amtstätigkeiten ohne eine kostendeckende Gebühr durchführen. Dadurch wird gewährleistet, dass jedermann notarielle Beratung und Vertragsgestaltung in Anspruch nehmen kann, unabhängig von Vermögen oder Wert des Geschäfts. Das Notarkostenrecht stellt nämlich ein besonders soziales Wert-Gebührensystem auf, das jedermann den Zugang zu notariellen Amtstätigkeiten ermöglicht.
                        <br /><br />
                        Beratung inklusive. Das notarielle Kostenrecht hat einen weiteren Vorteil für Sie als Bürgerin oder Bürger: Unsere Beratung einschließlich der Entwurfstätigkeit ist in der Beurkundungsgebühr enthalten, unabhängig von der Schwierigkeit, dem Aufwand oder der Anzahl der Besprechungstermine.
                        <br /><br />
                        Günstig. Eine Harvard-Studie aus dem Jahr 2007 zur Kostenstruktur, Qualität und Effizienz typischer Grundstückstransaktionen in Deutschland, Frankreich, England, Schweden, Estland und den Vereinigten Staaten hat ergeben: Wir Notarinnen und Notare in Deutschland gewährleisten nicht nur in hohem Maße Rechtssicherheit, sondern sind im internationalen Vergleich auch sehr günstig.
                    </p>
                </div>
                <div className='max-w-[1240px] mx-auto px-10 border border-bv_akzent_dark border-t-0 py-4 text-justify'>
                    <h3 className='font-bold text-left text-xl cursor-pointer md:text-2xl' onClick={()=>{handleElemHide(2)}}>Berechnung der Kosten</h3>
                    <p className= {
                        costMathHide ? "hidden ease-in-out duration-500" : "my-2 ease-in-out duration-500" 
                    }>
                        Wertgebühren. Die Höhe der Notarkosten richtet sich ausschließlich nach Bedeutung und Wert des Geschäfts und nicht nach dem Arbeitsaufwand des Notars. Für jede Amtstätigkeit des Notars sieht das bundesweit einheitliche Gerichts- und Notarkostengesetz (GNotKG) einen bestimmten Gebührensatz vor. Ausgehend vom jeweiligen Gebührensatz errechnet sich die konkrete Gebühr nach der vom Geschäftswert abhängigen Gebührenstaffelung. Die Beurkundungsgebühr umfasst dabei die umfassende Beratung durch den Notar, die Entwurfsfertigung sowie die Beurkundung im engeren Sinne.
                        <br /><br />
                        Gebührensatz. Für einseitige Erklärungen muss die volle Gebühr (1,0) berechnet werden, für Verträge und Beschlüsse kommt die doppelte Gebühr (2,0) zum Ansatz. Für Betreuungs- und Vollzugstätigkeiten des Notars beträgt der Gebührensatz oft 0,5. Beschränkt sich die Vollzugstätigkeit des Notars aber beispielsweise auf die Einholung eines Vorkaufsrechtszeugnisses nach § 28 Abs. 1 des Baugesetzbuchs, beträgt die Gebühr höchstens 50,-- €.
                        <br /><br />
                        Geschäftswert. Das GNotKG legt fest, wie der Geschäftswert für die Gebührenberechnung zu ermitteln ist. Dies ist zum Beispiel bei einem Kaufvertrag regelmäßig der Kaufpreis, bei Generalvollmachten das Bruttovermögen des Vollmachtgebers und bei Testamenten das Reinvermögen des Erblassers, wobei Schulden nur bis maximal zur Hälfte des Aktivvermögens abzugsfähig sind.
                        <br /><br />
                        Gebührenrechner. Die Höhe der Notargebühren kann mit Hilfe eines von der Bundesnotarkammer erstellten Gebührenrechners auf Basis des Programms Microsoft Excel™ ermittelt werden. Der Gebührenrechner kann auch von dieser Internetseite heruntergeladen werden. Für die Richtigkeit der Berechnung übernehmen wir keine Gewähr.
                        <br /><br />
                        Geschäftsprüfung. Gegenstand der regelmäßigen Geschäftsprüfungen durch den Präsidenten des Landgerichts bzw. die Prüfungsabteilung der Notarkasse ist auch der ordnungsgemäße Kostenansatz. Bei Unregelmäßigkeiten ist der Notar verpflichtet, Gebühren nachzufordern oder zurückzuerstatten. Falsche Wertangaben der Beteiligten sind strafbar.
                    </p>
                </div>
                <div className='max-w-[1240px] mx-auto px-10 py-4 border border-bv_akzent_dark border-t-0 text-justify'>
                    <h3 className='font-bold text-left text-xl cursor-pointer md:text-2xl' onClick={()=>{handleElemHide(3)}}>Beispielrechnung: Guter Rat muss nicht teuer sein</h3>
                    <p className= {
                        costExHide ? "hidden ease-in-out duration-500" : "my-2 ease-in-out duration-500" 
                    }>
                        Notarielle Urkunden haben häufig handfeste Kostenvorteile: So ersetzt das vom Notar beurkundete Testament in der Regel den ansonsten erforderlichen Erbschein. Der Erbschein kostet aber im Ergebnis fast doppelt so viel wie Beratung, Entwurf sowie Beurkundung des Testaments durch den Notar. Dabei erteilen wir Notare nicht nur professionellen Rat hinsichtlich Erbeinsetzung, Vermächtnissen und Teilungsanordnungen, sondern errichten darüber eine öffentliche Urkunde mit besonderer Beweiskraft. So wird sichergestellt, dass die letztwillige Verfügung nicht verschwindet und im Todesfall aufgefunden wird.
                        <br /><br />
                        Gebühren für den Entwurf einer Urkunde werden nur dann in Rechnung gestellt, wenn es nicht zur Beurkundung durch den Notar kommt. Fertigt ein Dritter, z.B. ein Rechtsanwalt, einen Entwurf, so fällt auch in diesem Fall die übliche Beurkundungsgebühr an.
                        <br /><br />
                        Auf den Seiten der Bundesnotarkammer finden Sie konkrete Berechnungsbeispiele zu Notarkosten. Bitte beachten Sie, dass im Einzelfall andere Auslagen entstehen können. Die dort aufgeführten Berechnungsbeispiele erfassen nur typische, wenn auch sorgfältig zusammengestellte Fallkonstellationen. Aus versehentlichen Fehlern in den Berechnungsbeispielen können also gegenüber dem einzelnen Notar oder der Bundesnotarkammer keine Ansprüche hergeleitet werden.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Notar;