import React, { forwardRef }  from 'react';


const Footer = forwardRef(({handleNavClick}, ref) => {
    
    return (
        <div ref={ref} className=' bg-bv_akzent_dark text-bv_font_light min-h-[200px] p-8'>
            <div className='flex justify-between flex-wrap gap-6 items-baseline max-w-[1240px] mx-auto'>
                <div>
                    <h2 className='text-bv_font_light font-bold text-lg'>Kontakt</h2>
                    <p>Notarin Sabine Klink</p>
                    <p>Schloßstraße 12</p>
                    <p>18246 Bützow | DE</p>
                    <p>E-Mail: <a href={'mailto:notarin.klink@sabine-klink.de'} className='hover:font-bold'>notarin.klink@sabine-klink.de</a></p>
                </div>
                <ul>
                    <li><a href='/Datenschutz' className='hover:font-bold'>Datenschutz</a></li>
                    <li><a href='/Impressum' className='hover:font-bold'>Impressum</a></li>
                </ul>
            </div>
            <div>
                <p className='pt-4 text-center text-sm'>Sabine Klink &copy; 2023</p>
            </div>
        </div>
    );
})

export default Footer;