import React from 'react';

function Impressum() {
    
    return ( 
    <div className='bg-bv_akzent_light text-bv_font_dark'>
        <div className='max-w-[1240px] mx-auto w-full p-4'>
            <h1 className='text-3xl font-bold text-center'>Impressum</h1>
            <div className='flex justify-center flex-wrap gap-10'>
                <div className='w-[500px]'>
                    <div className='my-4'>
                        <h2 className='text-xl font-bold'>Verantwotlich</h2>
                        <p>Sabine Klink</p>
                    </div>
                    <div className='my-4'>
                        <h2 className='text-xl font-bold'>Adresse</h2>
                        <p>
                            Schloßstraße 12 <br></br>
                            18246 Bützow
                        </p>
                    </div>
                    <div className='my-4'>
                        <h2 className='text-xl font-bold'>Telefon</h2>
                        <p>
                            038461 / 3260
                        </p>
                    </div>
                    <div className='my-4'>
                        <h2 className='text-xl font-bold'>Telefax</h2>
                        <p>
                            038461 / 65257
                        </p>
                    </div>
                    <div className='my-4'>
                        <h2 className='text-xl font-bold'>E-Mail</h2>
                        <a href={'mailto:notarin.klink@sabine-klink.de'} className='hover:font-bold'>notarin.klink@sabine-klink.de</a>
                    </div>
                    <div className='my-4'>
                        <h2 className='text-xl font-bold'>Berufsbezeichnung</h2>
                        <p>Notarin<br /><br />Die Berufsbezeichnung wurde in der Bundesrepublik Deutschland verliehen.</p>
                    </div>
                </div>
                <div className='w-[500px]'>
                    <div className='my-4'>
                        <h2 className='text-xl font-bold'>Zuständige Notarkammer</h2>
                        <p>Notarkammer Mecklenburg-Vorpommern<br />
                        Alexandrinenstr. 26<br />
                        19055 Schwerin <br />
                        Tel. 0385/ 5 81 25 75 <br />
                        Fax: 0385/ 5 81 25 74 <br />
                        E-Mail: <a href={'mailto:notk-mv@notarnet.de'} className='hover:font-bold'>notk-mv@notarnet.de</a><br />
                        <a href={'http://www.notarkammer-mv.de'} className='hover:font-bold'>http://www.notarkammer-mv.de</a>
                        </p>
                    </div>
                    <div className='my-4'>
                        <h2 className='text-xl font-bold'>Steuernummer</h2>
                        <p>086/239/00410</p>
                    </div>
                    <div className='my-4'>
                        <h2 className='text-xl font-bold'>Berufsrechtliche Regelungen</h2>
                        <p>Bundesnotarordnung, Beurkundungsgesetz und Notar- und Gerichtskostengesetz (GNotKG) Richtlinien für die Amtspflichten und sonstigen Pflichten der Notarinnen und Notare Dienstordnung für Notarinnen und Notare.</p>
                    </div>
                    <div className='my-4'>
                        <h2 className='text-xl font-bold'>Haftungsausschluss</h2>
                        <p>Auf Gestaltung und Inhalt der verlinkten externen Seiten im Internet besteht kein Einfluss. Jede Haftung wird diesbezüglich ausgeschlossen. Eine Gewähr für die Aktualität, Richtigkeit, Vollständigkeit oder Qualität der dort bereitgestellten Informationen wird nicht übernommen.</p>
                    </div>
                </div>
                <div className='my-4'>
                        <h2 className='text-xl font-bold'>Urheberrecht</h2>
                        <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.<br /><br />
                        Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen. <br /><br />
                        Webdesign und Umsetzung: Steffen Klink Baltic Visuals<br></br>
                        Texte: Sabine Klink<br></br><br></br>
                        Bilder: &copy; <a href="https://www.baltic-visuals.de">Baltic Visuals</a>
                         </p>
                    </div>
            </div>
        </div>
    </div> );
}

export default Impressum;