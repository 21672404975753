import React, {useState} from 'react';

function CookieBanner() {
    const [clickState, setClick] = useState(false);
    const handleClickState = () =>{
        setClick(!clickState);
    }
    return ( 
    <div className={!clickState ? 'fixed bottom-0 left-0 z-[101] bg-[#2a2a2a] text-bv_bg_light opacity-90 w-min-[300px] w-full p-6 mx-auto ease-in-out duration-500' : 'fixed bottom-0 left-[100%] z-[101] bg-[#2a2a2a] text-bv_bg_light opacity-90 w-min-[300px] w-[85%] p-6 mx-auto md:w-[600px] ease-in-out duration-500'}>
        <h2 className='text-2xl'>Datenschutz und Cookie-Richtlinie</h2>
        <p>Diese Webseite verwendet keine Cookies. Es werden keine Datenalaysen durch Drittanbietersoftware durchgeführt. Die Schriftarten die auf dieser Webseite zu finden sind, werden vom Server der Webseite geladen und nicht von Servern Dritter. Durch Nutzung dieser Webseite stimmen Sie den geltenden Datenschutzrichtlinien dieser Webseite zu. Weitere Informationen finden Sie hier:</p>
        <ul className='flex justify-around flex-wrap my-4 gap-5 mx-auto'>
            <li><a href='/Datenschutz' className='font-bold px-4 hover:bg-bv_bg_light hover:rounded-md hover:text-bv_font_dark'>Impressum</a></li>
            <li><a href='/Datenschutz' className='font-bold px-4 hover:bg-bv_bg_light hover:rounded-md hover:text-bv_font_dark'>Datenschutz</a></li>
        </ul>
        <div className='flex justify-center'>
        <button onClick={handleClickState} className='font-bold px-4 bg-bv_bg_light rounded-md text-bv_font_dark'>Verstanden!</button>
        </div>
        
    </div> );
}

export default CookieBanner;